import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import * as QRCode from "qrcode";
import { SingletonService } from "singleton";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyAccountsModel } from "components/models/YapilyModels/YapilyAccountsModel";
import { default as env } from "../../../../config/environment.json";
import { User } from "components/models/UserModel";
import { connectTo, Store } from "aurelia-store";
import { State } from "state";

@connectTo()
@autoinject
export class generateQrCodeReceive {
  private qrcode_canvas;
  private qrCodeAmount: number;
  private qrCodeReference: string;
  private accounts: Array<YapilyAccountModel>;
  private currentAccountIndex: number;
  private receivedAccounts: YapilyAccountsModel;
  private myBanks: Array<YapilyBankModel>;
  private currentBankIndex: number;
  private url: string;
  private me: User;
  private isCopied: boolean = false;
  private qrCodeSource: string = "";
  private loader: boolean = true;

  constructor(
    private yapilyHttpClient: YapilyHttpClient,
    private router: Router,
    private authService: AuthService,
    private singleton: SingletonService,
    private state: State,
    private store: Store<State>
  ) {}

  bind() {
    this.qrCodeAmount = this.state.makePayment.amount
    this.setQRCode();
  }

  setQRCode() {
    const QRData: string = `BCD\n002\n2\nSCT\n\n${this.state.me.displayName}\n${this.state.wallets[this.state.selectedAccountIndex].identification}\nEUR${this.state.makePayment.amount}\n\n\n`;
    QRCode.toCanvas(this.qrcode_canvas, QRData,
    {
      color: {
        dark: "#dfe0e2",
        light: "#0000",
      },
    });
    this.loader = false;
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }

  share() {
    let data = { url: this.url };
    let sharePromise = window.navigator.share(data);
  }
  copy() {
    navigator.clipboard.writeText(this.qrCodeSource);
    this.isCopied = true;
  }
}
