import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import * as QRCode from "qrcode";
import { default as env } from "../../../../config/environment.json";
import { I18N } from "aurelia-i18n";
import { User } from "components/models/UserModel";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";

@connectTo()
@autoinject
export class GenerateQrCode {
  private qrcode_canvas;
  private qrCodeAmount;
  private qrCodeReference;
  private referenceStruct: string;
  private isCommunicationError: boolean = false;
  private isCommunicationTypeStructured: boolean = false;
  private shaking: boolean = false;
  private me: User;
  private sanitizedCommentary: string = "";
  private state: State;

  constructor(
    private router: Router,
    private i18n: I18N,
    private store: Store<State>
  ) {}

  setQRCode() {
    const iban = this.state.wallets[this.state.selectedAccountIndex].identification;
    QRCode.toCanvas(
      this.qrcode_canvas,
      env.front_front +
        "/payments?identification=" +
        iban +
        "&comment=" +
        this.qrCodeReference +
        `&name=${this.state.me.displayName}` +
        `&amount=${this.qrCodeAmount}`,
      {
        color: {
          dark: "#dfe0e2",
          light: "#0000",
        },
      },
      (error) => {
        if (error) console.log(error);
      }
    );
  }
  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }


  getRandomCommunication() {
    let randomCommunications = [
      "Café de l'autre jour",
      "Merci pour la pizza",
      "Joyeux anniversaire",
      "Pour le cocktail",
      "Achète-toi des chips",
      "Voici ma part",
    ];
    return randomCommunications[
      Math.floor(Math.random() * randomCommunications.length)
    ];
  }

  referenceChanged() {
    if (!this.isCommunicationTypeStructured) return;

    if (this.referenceStruct.length == 3) {
      this.referenceStruct = this.referenceStruct + "/";
    }
    if (this.referenceStruct.length == 7) {
      this.referenceStruct = this.referenceStruct + "/";
    }
    if (this.referenceStruct.length > 13) {
      this.referenceStruct = this.referenceStruct.substring(0, 13);
    }
  }

  goToQrCodeReceive() {
    this.store.dispatch("set.make_payment.amount.and.currency", this.qrCodeAmount, this.state.wallets[this.state.selectedAccountIndex].currency);
    this.router.navigateToRoute("generate_qr_code_receive");
  }

  sanitizeCommentary(newValue: string) {
    const lowercasedValue = newValue.toLowerCase();
    this.sanitizedCommentary = lowercasedValue.replace(/[^a-z0-9_]/g, "_");
    this.qrCodeReference = this.sanitizedCommentary;
  }
}
