import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';
import { SavingsHttpClient } from 'http_clients/SavingsHttpClient';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { User } from 'components/models/UserModel';
import { SearchSavingModel } from 'components/models/SearchSavingModel';
import { SavingTransactionModel } from 'components/models/SavingTransactionModel';
import { Savings_pots } from 'components/models/SavingModel';
import { json } from 'aurelia-fetch-client';
import { SingletonService } from 'singleton';
import BankaService from 'services/banka.service';
import { YapilyBankModel } from 'components/models/YapilyModels/YapilyBankModel';
import { SearchTransactionSavingModel } from 'components/models/SearchTransactionSavingModel';
@autoinject
export class SavepotId {
  private router: Router;
  private me: User;
  private saving_pot_id: string;
  private savingTransactionId: Savings_pots;
  private savingTransactionDetails: SearchTransactionSavingModel;
  private potTransactionInformation: SavingTransactionModel = new SavingTransactionModel();
  private currentBank: YapilyBankModel;
  private currentAccount;
  private amount: number;
  private total: number;
  private isLoading: boolean = false;

  private isDeletePopupOpened = false;
  private selectedSavingPot;

  constructor(private authService: AuthService, router: Router,
    private savingHttpClient: SavingsHttpClient,
    private singletonService: SingletonService,
    private bankaService: BankaService) {

    this.router = router
    this.getMe();
  }

  activate(params) {
    console.log(params.saving_pot_id)
    this.saving_pot_id = params.saving_pot_id
    this.currentBank = this.singletonService.getMyBanks()[this.singletonService.getCurrentBankIndex()];
    this.currentAccount = this.currentBank.accounts[this.singletonService.getCurrentAccountIndex()];
    this.getCurrentSavePotTransactionByID();
    this.getSavePot()
  }
  async getMe() {
    this.me = await this.authService.getMe();
  }

  async createSavePotTransection() {
    this.isLoading = true;
    this.potTransactionInformation.bank_id = this.currentBank.institution;
    this.potTransactionInformation.account_id = this.currentAccount;
    this.potTransactionInformation.fk_pot_id = this.saving_pot_id;
    this.potTransactionInformation.amount = this.amount;
    let httpRequest = await this.savingHttpClient.fetch('/saving_transaction/', {
      method: "POST",
      body: json(this.potTransactionInformation)
    })
    let is200ok = await checkResponseStatus(httpRequest);
    let potTransactionInformation = await is200ok.json()
    this.getCurrentSavePotTransactionByID();
    this.isLoading = false;
  }

  async getSavePot() {
    let httpRequest = await this.savingHttpClient.fetch('/saving_pot/' + this.saving_pot_id)
    let is200ok = await checkResponseStatus(httpRequest);
    this.savingTransactionId = await is200ok.json();
  }

  async getCurrentSavePotTransactionByID() {
    let httpRequest = await this.savingHttpClient.fetch('/saving_transaction/?fk_pot_id=' + this.saving_pot_id + '&limit=100000')
    let is200ok = await checkResponseStatus(httpRequest);
    this.savingTransactionDetails = await is200ok.json();
    this.calculateTotalAmount(this.savingTransactionDetails.docs)
  }

  async calculateTotalAmount(savingTransactionDetails) {
    this.total = 0
    for (let i = 0; i < savingTransactionDetails.length; i++) {
      this.total = this.total + savingTransactionDetails[i].amount
    }
    return this.total
  }
  async deleteTransaction(currentTransaction: SavingTransactionModel) {
    let httpRequest = await this.savingHttpClient.fetch('/saving_transaction/' + currentTransaction._id, {
      method: "DELETE"
    })
    let is200ok = await checkResponseStatus(httpRequest);
    let response = await await is200ok.json();
    this.getCurrentSavePotTransactionByID()
  }

  onDeletePopupAccept() {
    console.log("oui")
    this.deleteTransaction(this.selectedSavingPot)
    this.isDeletePopupOpened = false;
  }

  onDeletePopupReject() {
    this.isDeletePopupOpened = false;
  }

  promptPotDeletetion(savingPot) {
    this.selectedSavingPot = savingPot;
    this.isDeletePopupOpened = true
  }
}
