// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./generate_qr_code_receive.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="generate_qr_code_receive" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.generate_qr_code.generate_qr_code_receive1"></h1>
    </div>
    <div class="flex-grow"></div>
    <loader-ring if.bind="loader"></loader-ring>
    <canvas id="qrcode_canvas" ref="qrcode_canvas"></canvas>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" t="home.finish" click.delegate="goToHome()">
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;