// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./reauthorize_bank.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="reauthorize_bank" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.agreement.agreements_users1"></h1>
    </div>
    <div class="logo_and_bank row">
      <div class="blackground">
        <img src="${___HTML_LOADER_IMPORT_1___}">
      </div>
      <span>X</span>
      <div class="blackground">
        <img id="bank_img" src.bind="bank.selectedAccount.picture" alt="">
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="all-paragraphs">
      <div class="paragraph">
        <h2 i18n="bank.agreement.agreements_users1"></h2>
        <span class="gray" i18n="bank.agreement.agreements_users2"></span>
      </div>
      <div class="flex-grow"></div>
      <div class="paragraph">
        <h2 i18n="bank.agreement.agreements_users3"></h2>
        <span class="gray" i18n="bank.agreement.agreements_users4"></span>
      </div>
      <div class="flex-grow"></div>
      <div class="paragraph">
        <h2 i18n="bank.agreement.agreements_users5"></h2>
        <span class="gray" i18n="bank.agreement.agreements_users6"></span>
      </div>
    </div>
    <span class='error' class.bind="isError ? 'shake' : ''" if.bind='isError'>
      \${errorMessage}
    </span>
    <div class="flex-grow"></div>
    <div class="labels">
      <label class="container" class.bind="shaking ? 'shake' : ''">
       <p style="margin:0px; font-weight: 500;">
          <span i18n="bank.agreement.agreements_users_accept_banka_conditions"></span>
          <span click.delegate="router.navigateToRoute('terms')" class="white" i18n="bank.agreement.agreements_users_mybanka"></span>
        </p>      
        <input type="checkbox" checked.bind="isOurConditionsAccepted" />
        <span class="checkmark"></span>
      </label>
      <label class="container" class.bind="shaking ? 'shake' : ''">
        <p style="margin:0px; font-weight: 500;">
          <span i18n="bank.agreement.agreements_users_accept_yapily_conditions"></span>
          <span onclick="window.location = 'https://www.yapily.com/legal/yapilyconnect-terms-and-conditions/'" class="white" i18n="bank.agreement.agreements_users_yapily_connect"></span>
        </p>
        <input type="checkbox" checked.bind="isYapilyConditionsAccepted" />
        <span class="checkmark"></span>
      </label>
    </div>
    <button class="btn btn-primary fakeDisabled"
      if.bind="!isOurConditionsAccepted || !isYapilyConditionsAccepted" click.delegate="shakeError()">
      <span i18n="bank.agreement.agreements_users12"></span>
    </button>
    <button class="btn btn-primary" if.bind="isYapilyConditionsAccepted && isOurConditionsAccepted"
      click.delegate="makeAgreement()" disabled.bind="isLoading">
      <span i18n="bank.agreement.agreements_users12" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;