import { AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';
import { SavingsHttpClient } from 'http_clients/SavingsHttpClient';
import { SingletonService } from 'singleton';
import { Savings_pots } from 'components/models/SavingModel';
import { json } from 'aurelia-fetch-client';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { SavingTransactionModel } from 'components/models/SavingTransactionModel';
import { FileUploadHttpClients } from 'http_clients/FileUploadHttpClients';
import { I18N } from 'aurelia-i18n';

import { autoinject, computedFrom, inject } from 'aurelia-framework';
@autoinject()
export class savepotAdd {
  private savingTransactionInformation: Savings_pots = new Savings_pots();
  private name: string;
  private goal_amount: number;
  private urlPic: string;
  private selectedFile;
  private myInput;
  private formData: FormData;
  private potTransactionInformation: SavingTransactionModel
  private IsImageChosen: boolean = false;
  private displayError = false;
  private errorMessage;
  private isLoading: boolean = false;
  private shaking: boolean = false;
  previewPic: HTMLImageElement;

  constructor(
    private authService: AuthService,
    private router: Router,
    private savingHttpClient: SavingsHttpClient,
    private fileUploadHttpClient: FileUploadHttpClients,
    private i18n: I18N) {
  }

  async AddNewPot() {
    let urlPic
    try {
      urlPic = await this.uploadObjectiveImage()
    } catch {
      urlPic = undefined
      console.warn("pas d'image ou erreur")
    }
    if (this.checkIfEmpty()) {
      this.displayError = true;
      this.errorMessage = await this.i18n.tr("savepot_addts1");
    }
    let httpRequest = await this.savingHttpClient.fetch('/saving_pot', {
      method: 'POST',
      body: JSON.stringify({
        name: this.name,
        goal_amount: this.goal_amount,
        urlPic: urlPic
      })
    })
    let is200ok = await checkResponseStatus(httpRequest);
    let savingTransactionDetails = await is200ok.json();
    this.router.navigateToRoute('savepot_home')
  }

  checkIfEmpty(): boolean {
    return (this.name == "") || (this.name == undefined) || (this.goal_amount == 0) || (this.goal_amount == undefined)
  }


  changeProfilePic() {
    this.myInput.click();
    console.log("clicked")
  }

  onSelectFile() {
    console.log("selected")
    this.formData = new FormData()
    this.formData.append('upload', this.selectedFile[0]);


    // * actuellement file est de type blob
    // * il faut le convertir en URL
    // * pour pouvoir le mettre en attribut
    // * à notre image pour le preview
    const file = this.selectedFile[0];

    console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.previewPic.src = reader.result.toString();
      });
      reader.readAsDataURL(file);
    }
  }

  async uploadObjectiveImage() {
    // await httpRespose
    let httpResult = await this.fileUploadHttpClient.fetch('/fileupload/', {
      method: 'POST',
      body: this.formData
    });
    let isHttpOk = await checkResponseStatus(httpResult)
    let json_response = await isHttpOk.json();
    return json_response.url; // URL IS INSIDE THE JSON
  }

  async shakeError() {
    console.log("RAAAOOUULLLL")
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

}
