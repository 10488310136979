// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./savepot_home.scss"></require>
  <require from="../../../../components/ValueConverters/currency-denomination"></require>
  <require from="../../../../components/validation-popup/validation-popup"></require>
  <section id="savepot_home" class="flex-column">
    <validation-popup if.bind="isDeletePopupOpened" 
      message.bind="'Etes-vous sur de vouloir supprimer le pot ?'"
      on-accept.call="onDeletePopupAccept()" on-reject.call="onDeletePopupReject()">
    </validation-popup>
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="savepot_home1"></h1>
    </div>
    <div class="bubble">
      <p> <span i18n="savepot_home2"></span></p>
      <h2> \${totalAllPots}€ </h2>
    </div>
    <div class="spacer-32"></div>
    <div class="all_savings_header flex-row">
      <h4 id="myPots" i18n="savepot_home4"></h4>
    </div>
    <div class="all_savings column">
      <div class="savings flex-row" repeat.for="currentSavingPot of savingPotDetails" click.delegate="goToSavePotId(currentSavingPot._id)">
        <img src.bind="currentSavingPot.urlPic || 'img/logo_white.png'">
        <div class="money_saved flex-column">
          <p class="title" i18n="name"></p>
          <p>\${currentSavingPot.name}</p>
        </div>
        <div class="money_saved flex-column">
          <p class="title" i18n="savepot_home6"></p>
          <p>\${currentSavingPot.total}/\${currentSavingPot.goal_amount}€</p>
        </div>
        <a click.delegate="promptPotDeletetion(currentSavingPot)">X</a>
        <!-- <div class="savings_content flex-row" >
          
          <div class="money_saved column">
            
          </div>
          
        </div>
        <div class="flex-grow"></div>
        <div class="delete_savepot flex-column" >
          
        </div> -->
      </div>
    </div>
</template>
`;
// Exports
export default code;