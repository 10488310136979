import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { AuthService } from 'aurelia-auth';
import { Router } from 'aurelia-router';
import { SavingsHttpClient } from 'http_clients/SavingsHttpClient';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { SearchSavingModel } from 'components/models/SearchSavingModel';
import { User } from 'components/models/UserModel';
import { Savings_pots } from 'components/models/SavingModel';
import { SearchTransactionSavingModel } from 'components/models/SearchTransactionSavingModel';
import { SavingTransactionModel } from 'components/models/SavingTransactionModel';

@autoinject
export class savepot_home {
  private router: Router;
  private me: User;
  private savingPotDetails: Array<Savings_pots>;
  private savingTransactionDetails: Array<SavingTransactionModel>
  private moneySaved: SearchTransactionSavingModel;
  private totalAllPots: number;

  private isDeletePopupOpened = false;
  private selectedSavingPot;

  constructor(private authService: AuthService, router: Router,
    private savingHttpClient: SavingsHttpClient) {
    this.router = router
    this.getMe();
  }
  goToAddSavePot() {
    this.router.navigateToRoute('savepot_add');
  }

  async getMe() {
    this.me = await this.authService.getMe();
    this.getSavePot();
  }

  async deleteCurrentSavingPot(saving_pot) {
    let response = await this.savingHttpClient.fetch('/saving_pot/' + saving_pot._id, {
      method: "DELETE"
    })
    let is200ok = await checkResponseStatus(response);
    this.savingPotDetails = await response.json();
  }

  onDeletePopupAccept() {
    console.log("oui")
    this.deleteCurrentSavingPot(this.selectedSavingPot)
    this.isDeletePopupOpened = false;
  }

  onDeletePopupReject() {
    this.isDeletePopupOpened = false;
  }

  promptPotDeletetion(savingPot) {
    this.selectedSavingPot = savingPot;
    this.isDeletePopupOpened = true
  }

  async getSavePot() {
    let httpRequest = await this.savingHttpClient.fetch('/saving_pot/?limit=100')
    let is200ok = await checkResponseStatus(httpRequest);
    this.savingPotDetails = await is200ok.json();
    console.log(this.savingPotDetails)
    for (let i = 0; i < this.savingPotDetails.length; i++) {
      this.savingPotDetails[i].transactions = await this.getSavingPotTransactionById(this.savingPotDetails[i]._id)
      this.savingPotDetails[i].total = await this.calculateTotalAmountOfTransaction(this.savingPotDetails[i].transactions)
    }
    this.getMyTotalSaved();
  }

  goToSavePotId(saving_pot_id) {
    console.log(saving_pot_id)
    this.router.navigateToRoute('savepot_id', { saving_pot_id: saving_pot_id })
  }

  getMyTotalSaved() {
    let totalSavingPot: number = 0;
    for (let i = 0; i < this.savingPotDetails.length; i++) {
      totalSavingPot = this.savingPotDetails[i].total + totalSavingPot
    }
    this.totalAllPots = totalSavingPot;
  }


  async getSavingPotTransactionById(savingPotDetails_id) {
    let httpRequest = await this.savingHttpClient.fetch('/saving_transaction/?fk_pot_id=' + savingPotDetails_id + '&limit=1000000')
    let is200ok = await checkResponseStatus(httpRequest);
    return (await is200ok.json()).docs;
  }

  async calculateTotalAmountOfTransaction(savingTransactionDetails) {
    let total = 0;
    for (let i = 0; i < savingTransactionDetails.length; i++) {
      total = total + savingTransactionDetails[i].amount
    }
    return total
  }

}
