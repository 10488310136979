// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
    <require from="./savepot_id.scss"></require>
    <require from="../../../../components/loader-ring/loader-ring"></require>
    <require from="../../../../components/validation-popup/validation-popup"></require>
    <require from="../../../../components/date-format-short"></require>

    <section id="savepot_id" class="flex-column">
        <validation-popup if.bind="isDeletePopupOpened" message.bind="'Etes-vous sur de vouloir supprimer le pot ?'"
            on-accept.call="onDeletePopupAccept()" on-reject.call="onDeletePopupReject()">
        </validation-popup>
        <div id="header" class="flex-row">
            <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
            <h1><span i18n="savepot_id1"></span> \${savingTransactionId.name}</h1>
        </div>
        <div class="invested_so_far flex-column">
            <div class="image">
                <img src="\${savingTransactionId.urlPic || 'img/logo_white.png'}">
            </div>
            <h1>€\${total}/\${savingTransactionId.goal_amount}</h1>
            <h4> <span i18n="savepot_id2"></span></h4>
        </div>
        <div class="spacer-32"></div>
        <form id="add_money" class="inputs flex-column">
            <!-- TODO PLACEHOLDER TRANSLATION -->
            <input id="amount" value.bind="amount" type="number" i18n="[placeholder]savepot_id_placeholder"></input>
        </form>
        <button class="btn btn-primary" click.delegate="createSavePotTransection()" form="add_money"
            if.bind="!isLoading" disabled.bind="(amount == '')">
            <span i18n="savepot_id4"></span></button>
        <button class="btn btn-primary" click.delegate="createSavePotTransection()" form="add_money" if.bind="isLoading"
            disabled>
            <loader-ring class="loader-btn"></loader-ring>
        </button>
        <div class="spacer-32"></div>
        <h2 id="transactions_title" i18n="savepot_id5" if.bind="total != 0"></h2>
        <div class="latest_transactions flex-column">
            <div class="latest_transactions_body flex-row"
                repeat.for="currentTransactionDetails of savingTransactionDetails.docs ">
                <!--REPEAT FOR ICI ANTHO-->
                <p>\${currentTransactionDetails.createdAt | dateFormatShort}</p>
                <!--date-->
                <h5>+\${currentTransactionDetails.amount}€</h5>
                <!--montant-->
                <div class="delete_savepot" click.delegate="promptPotDeletetion(currentTransactionDetails)">
                    <h4>X</h4>
                </div>
            </div>
        </div>
        <div class="spacer-16"></div>
</template>
`;
// Exports
export default code;