// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
    <require from="./savepot_add.scss"></require>
    <section id="savepot_add" class="flex-column">
        <div id="header" class="flex-row">
            <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
            <h1> <span i18n="savepot_add1"></span></h1>
        </div>
        <div class="flex-grow"></div>
        <div class="image flex-column">
            <img src="${___HTML_LOADER_IMPORT_1___}" ref="previewPic" click.delegate="changeProfilePic()">
            <a click.delegate="changeProfilePic()" i18n="savepot_add3">Choisir une image</a>
            <input value.bind="urlPic" ref="myInput" type="file" files.bind="selectedFile" accept="image/*"
                change.delegate="onSelectFile()" style="display: none;" />
        </div>
        <div class="flex-grow"></div>
        <form id="add_savepot" class="inputs flex-column" class.bind="shaking ? 'shake' : ''">
            <div>
                <input id="name" value.bind="name" i18n="[placeholder]savepot_add_name" maxlength="20" autofocus>
            </div>
            <div>
                <input id="objective" value.bind="goal_amount" type="number" min="0" placeholder="0€">
            </div>
        </form>
        <div class="flex-grow"></div>
        <div class='error' show.bind='displayError'>
            <p>\${errorMessage}</p>
        </div>
        <div class="flex-grow"></div>
        <button class="btn btn-primary fakeDisabled" click.delegate="shakeError()" if.bind="!name && !goal_amount">
            <span t="savepot_add1"></span>
        </button>
        <button class="btn btn-primary" click.delegate="AddNewPot()" form="add_savepot" if.bind="name || goal_amount">
            <span i18n="savepot_add1"></span>
        </button>
        <div class="spacer-default"></div>
    </section>
</template>
`;
// Exports
export default code;