// Module
var code = `<template>
  <require from="./intermediate.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="payment_landing" class="flex-column">
    <div id="loading"class="flex-column">
      <div class="flex-grow"></div>
      <div class="payment_info flex-column">
        <animated-checkmark></animated-checkmark>
      </div>
      <div class="flex-grow"></div>
    </div>
  </section>
</template>
`;
// Exports
export default code;