import { YapilyAccountModel } from "./../../../../components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "./../../../../components/models/YapilyModels/YapilyBankModel";
import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { SingletonService } from "singleton";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { RecipientModel } from "components/models/RecipientModel";
import { RecipientsHttpClient } from "../../../../http_clients/RecipientsHttpClient";
import { YapilyPaymentRequestModel } from "components/models/YapilyModels/YapilyPaymentRequestModel";
import { YapilyPaymentsDetailsModel } from "components/models/YapilyModels/YapilyPaymentsDetailsModel";
import { YapilyBanksDetailsModel } from "components/models/YapilyModels/YapilyBanksDetailsModel";
import { User } from "components/models/UserModel";
import { default as env } from "../../../../../config/environment.json";
import { State } from "state";
import { connectTo } from "aurelia-store";
@connectTo()
@autoinject
export class Paymentlanding {
  private institution: YapilyBanksDetailsModel;
  private userUuid: string;
  private me: User;
  private recipients;
  private isIbanInBeneficiary: boolean = true;
  private ibanToCheck: string;
  private isCheckBoxChecked: boolean = false;
  private paramsConsent;
  private paramsInstitution;
  private paramsApplicationUserId;
  private paramsUserUuId;
  private test: string;
  private completed: boolean = false;
  private doWeHaveInfo: boolean = false;
  private paymentsAuthInformation: YapilyPaymentRequestModel;
  private received_consent: string;
  private paymentsDetails: YapilyPaymentsDetailsModel;
  private recipient: RecipientModel;
  private mybank: YapilyBankModel;
  private myaccount: YapilyAccountModel;

  constructor(
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private router: Router,
    private paramsLanding,
    private recipientsHttpClient: RecipientsHttpClient,
    private state: State
  ) {
    Date();
  }

  activate(params) {
    this.received_consent = params.consent;
    if (this.received_consent == undefined)
      this.router.navigateToRoute("payment_failure");
  }

  bind() {
    setTimeout(() => (this.goToHome()), 3000);
  }

  goToHome(){
    this.router.navigateToRoute("home");
  }
}

